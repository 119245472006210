@import url('https://rsms.me/inter/inter.css');

@font-face {
  font-family: 'FranklinGothic';
  font-style:  normal;
  font-weight: 700;
  src: url('../public/typefaces/Franklin Gothic/Web Fonts/FranklinGothicURW Hea/FranklinGothicURW-Hea.woff') format('woff');
}

@font-face {
  font-family: 'FranklinGothic';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url('../public/typefaces/Franklin Gothic/Web Fonts/FranklinGothicURW Med/FranklinGothicURW-Med.woff') format('woff');
}

@font-face {
  font-family: 'FranklinGothic';
  font-style:  normal;
  font-weight: 300;
  src: url('../public/typefaces/Franklin Gothic/Web Fonts/FranklinGothicURW Lig/FranklinGothicURW-Lig.woff') format('woff');
}


* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overscroll-behavior-y: contain;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}

body {
  overscroll-behavior: none;
  background: #151515;
  color: #a0a0a0;
  //font-family: 'Inter var', sans-serif;
  font-family: 'FranklinGothic', sans-serif;  
}

h1 {
  font-size: 30vw;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 0.7em;
  margin: 0;
  padding: 0;
}

a {
  pointer-events: all;
  color: #a0a0a0;
  text-decoration: none;
}

svg {
  fill: #a0a0a0;
  width: 80px;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.canvas {
  position: fixed !important;
  top: 0px;
  left: 0px;
  width: 100vw !important;
  height: 100vh !important;
}

.view {
  margin: 0.2em;
  width: 100vw;
  height: 100vh;
  display: inline-block;
}

#logoWrapper {
}

.showFromTop {
  animation: fade-in-top 2.0s 2.0s ease-in-out forwards;
}





#revealOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  background: black;
  pointer-events: none;
  opacity: 1;
}

.hide {
  animation: fade-out ease 2.0s 1.0s forwards;
}

.visible {
  opacity: 1;
}

#initialOverlay svg {
  width: 200px;
}

.bigTitle {
  font-size: 15vw;
  font-weight: 700;
  letter-spacing: -0.05em;
  line-height: 0.7em;
  margin: 0;
  padding: 0;
  color: black;
}

.textWrapper {
  position: fixed;
  top: 0px;
  left: 10vw;
  will-change: transform;
  height: 99vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: min(20rem, 50vw);
  color: white;
}


.textBox {
  width: 100%;
  position: relative;
  height: 15em;
  position: absolute;
}

#textBox1 {
  position: absolute;  
  top: 85vh;
}

#textBox2 {
  position: absolute;
  top: 145vh;
}


.textContent {
  opacity: 1;
  position: absolute;
  top: 0;
  font-weight: 300;
  font-size: 0.8em;
}

.textContent p {
  letter-spacing: 0.5px;
}

.textContent h2 {
  font-weight: 300;
  text-transform: uppercase;
}

.textContent button {
  font-family: 'FranklinGothic';
  line-height: 1em;
  text-transform: uppercase;
  background: none;
  color: white;
  font-weight: 300;
  border: 1px solid white;
  padding: 0.7em 1em 0.5em;
  font-size: 1rem;
  cursor: pointer;
  transition: all 1s;  
}

.textContent button:hover {
  background: white;
  color: black;
}

.showTextContent {
  animation: fade-in-content 3.0s 3.0s ease-in-out;
}

.hidden {
  opacity: 0;
}

@keyframes fade-in-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-in-top {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 1000px) {
  svg {
    width: 120px;
  }
}

@media (min-width: 800px) {
  .textContent {
    font-size: 1em;
  }

  #textBox1 {
    top: 100vh;
  }

  #textBox2 {
    top: 160vh;
  }
}